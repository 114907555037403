import React from "react"
import { Pagination } from "antd"
import SEO from "../../components/seo"
import Layout from "../../components/layout";
import { itemListDownload } from "../../utils/sidebar/item-list"
import Container from "../../components/container";
// import Button from "../../components/button";
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale } from "../../utils/typography"
import Footer from "../../components/footer";
// import { vP } from "../../components/gutters"
// import Card from "../../components/card";
import { Link, graphql } from "gatsby"

// const UpdatePage = ({ data, location }) => (
class UpdatePage extends React.Component {
  state = {
    updateList: [],
    pageSize: 20,
    currentPage: 1
  }

  componentDidMount() {
    const { edges } = this.props.data.allMarkdownRemark
    if (edges.length) {
      const start = (this.state.currentPage - 1) * this.state.pageSize, end = this.state.currentPage * this.state.pageSize;
      const updateList = edges.slice(start, end);
      this.setState({ updateList });
    }
  }

  changeUpdateList = (page) => {
    const { edges } = this.props.data.allMarkdownRemark
    const start = (page - 1) * this.state.pageSize, end = page * this.state.pageSize;
    const updateList = edges.slice(start, end);
    this.setState({updateList, currentPage: page});
  }
  
  render() {
    const { location } = this.props;
    const { totalCount } = this.props.data.allMarkdownRemark
    const { updateList, pageSize, currentPage } = this.state
    return (
      <Layout location={location} itemList={itemListDownload} contentTitle="更新说明">
          <SEO title="更新说明" />
          <Container overrideCSS={{maxWidth: '100%', padding: 24, minHeight: 'calc(100vh - 392px)'}}>
              {/* <ol>
                  {itemListUpdate.map((item, i) => {
                    return <li key={i}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></li>
                  })}
              </ol> */}
              <ol>
                {updateList.map(({ node }) => (
                  <li key={node.id}>
                    <Link to={node.fields.slug} css={{textDecoration: `none`}}>
                      {node.frontmatter.title}
                    </Link>
                  </li>
                ))}
              </ol>
              <div css={{ marginTop: 24, fontWeight: 600 }}>
                <Pagination
                  hideOnSinglePage={true}
                  total={totalCount}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={this.changeUpdateList}
                  showTotal={(total) => `共 ${Math.ceil(total / pageSize)} 页, 当前第 ${currentPage} 页`}
                />
              </div>
          </Container>
      </Layout>
    )
  }
}

const styles = {
    cards: {
        background: `#F5F5F5`,
        borderRadius: presets.radiusLg,
        display: `flex`,
        flexWrap: `wrap`,
        width: `100%`,
    },
    contact: {
        flex: `0 0 100%`,
        maxWidth: `100%`,
        padding: rhythm(presets.gutters.default / 2),
        [presets.Hd]: { padding: 24 },
    },
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
    summaryTitle: {
        margin: `0 0 50px 0`,
        padding: 0,
        alignItems: `center`,
        borderBottom: `1px solid ${colors.ui.border}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
}

export default UpdatePage

export const query = graphql`
    query {
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "update-versions"} } } sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
